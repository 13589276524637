// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v716demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'https://lnpbermuda-prod.ew.r.appspot.com/',
  // apiUrl: 'https://lnpbermuda-dev.ew.r.appspot.com/',
  firebaseConfig : {
    apiKey: "AIzaSyBBSk0bpYIqwi9qv6C7-XDTpjuTNFcMG8o",
   authDomain: "lnpbermuda-prod.firebaseapp.com",
   projectId: "lnpbermuda-prod",
   storageBucket: "lnpbermuda-prod.appspot.com",
   messagingSenderId: "15759652036",
   appId: "1:15759652036:web:bca32d921f040764e7c591"
   }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
